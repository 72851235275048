import { validateForms } from '../functions/validate-forms';

const rules = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Add name!'
      }
    ]
  },
  {
    ruleSelector: '.input-email',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Add email!'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Incorrect email!'
      }
    ]
  },
  {
    ruleSelector: '.input-checkbox',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Agreement required!'
      }
    ]
  },
];

const afterForm = () => {
  console.log('Sent');
  
};

validateForms('.contacts-form', rules, afterForm);
