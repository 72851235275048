const langArr = {
  "about": {
    "ru": "Обо мне",
    "en": "About me",
  },
  "portfolio": {
    "ru": "Мои проекты",
    "en": "My projects",
  },
  "contacts": {
    "ru": "Контакты",
    "en": "Contacts",
  },
  "": {
    "ru": "",
    "en": "",
  },
  "": {
    "ru": "",
    "en": "",
  },
  "": {
    "ru": "",
    "en": "",
  },
}
