const switcher = document.querySelector(".header__switch");
const allang = ["en", "ru"];

switcher.addEventListener("click", changeURLLanguage);

function changeURLLanguage(e) {
  let lang = e.target.id;
  location.href = window.location.pathname + "#" + lang;
  location.reload();
}

function changeLanguage() {
  let hash = window.location.hash;
  hash = hash.substr(1);
  console.log(hash);

  if (!allang.includes(hash)) {
    location.href = window.location.pathname + "#" + "ru";
    location.reload();
  }

  for (var i = 0; i < switcher.children.length; i++) {
    if (hash === switcher.children[i].id) {
      switcher.children[i].classList.add("active");
    }
  }

  for (let key in langArr) {
    let elem = document.querySelector(".lng-" + key);
    if (elem) {
      elem.innerHTML = langArr[key][hash];
    }
  }
}

const langArr = {
  about: {
    ru: "Обо мне",
    en: "About me",
  },
  portfolio: {
    ru: "Мои проекты",
    en: "My projects",
  },
  contacts: {
    ru: "Контакты",
    en: "Contacts",
  },
  menu: {
    ru: "Перейти в меню",
    en: "",
  },
  title: {
    ru: "Ирина Пронина",
    en: "Irina Pronina",
  },
  name: {
    ru: "<span>Ирина </span> Пронина",
    en: "<span>Irina </span> Pronina",
  },
  herosubtitle: {
    ru: "Привет! Я <span>FrontEnd разработчик</span>",
    en: "Hello! I am <span>FrontEnd developer</span>",
  },
  herotext: {
    ru: "Pанимаюсь разработкой сайтов и приложений с 2017 года. Подробнее узнать обо мне можно на этом сайте.",
    en: "I've been developing and promoting websites since 2017. You can learn more about me on this site.",
  },
  oncont: {
    ru: "На связи",
    en: "Get in touch",
  },
  summarytitle: {
    ru: "Привет! Меня зовут Ирина Пронина",
    en: "Hello! I am Irina Pronina",
  },
  summaryp: {
    ru: "Занимаюсь разработкой и продвижением сайтов с 2017 года.",
    en: "I've been developing and promoting websites since 2017.",
  },
  summaryp2: {
    ru: "Получила высшее Лингвистическое образование и 7 лет работала в сфере международных продаж (дошла до должности Руководителя отдела продаж). Потом открыла свой бизнес и начала его продвигать. Какие тостратегии действовали, какие то нет, я восполняла свои пробелы в знаниях маркетинга, сайтостроения имногих смежных областей. Применяла все знания на практике.",
    en: "Received a higher Linguistic education and worked for 7 years in the field of international sales (reached the position of Head of Sales Department). Then she opened her own business and began to promote it. Some strategies worked, some did not, I filled in my gaps in knowledge of marketing, website building and many related areas. Put all knowledge into practice.",
  },
  summaryp3: {
    ru: "Спустя 3 года я поняла, что заниматься сайтом и его продвижением мне интереснее всего. И решила сконцентрироваться на этом. Сейчас я делаю сайты и приложения в помощь другим бизнесам и в этом нашла себя.",
    en: "After 3 years, I realized that it was most interesting for me to deal with the site and its promotion. And I decided to focus on it. Now I make websites and applications to help other businesses, and I found myself in this.",
  },
  summaryitem1: {
    ru: "Имя:",
    en: "Name:",
  },
  summaryitem2: {
    ru: "Ирина Пронина",
    en: "Irina Pronina",
  },
  summaryitem3: {
    ru: "Телефон:",
    en: "Phone:",
  },
  summaryitem4: {
    ru: "Телеграм:",
    en: "Telegram:",
  },
  aboutpdf: {
    ru: "Обо мне в PDF",
    en: "About me in PDF",
  },
  contactssubtitle: {
    ru: "Мои контакты",
    en: "My Address Info",
  },
  contactstitle: {
    ru: "На <span>связи</span>",
    en: "Get <span>in touch</span>",
  },
  contactsdesc: {
    ru: "Проще всего со мной связаться по телефону:",
    en: "Connect with Me via Phone:",
  },
  contactsdescem: {
    ru: "или написать Email:",
    en: "or Email:",
  },
  contactstel: {
    ru: "Телефон",
    en: "Phone Number",
  },
  contactsub: {
    ru: "Напишите мне",
    en: "Contact me",
  },
  contactsdescrtwo: {
    ru: "Если Вы хотите оставить отзыв, договориться о сотрудничестве или обсудить проект- напишите мне.",
    en: "Please, complete the form If you want to leave a review, agree on cooperation or discuss a project",
  },
  contactscheckbox: {
    ru: "Даю согласие на обработку персональных данных",
    en: "I agree to the processing of personal data",
  },
  contactsbtn: {
    ru: "Отправить",
    en: "Submit",
  },
  abouttitle: {
    ru: "Обо <span>Мне</span>",
    en: "About <span>me</span>",
  },
  myskills: {
    ru: "Мои <span>Навыки</span>",
    en: "My <span>Skills</span>",
  },
  programm: {
    ru: "Программы и <span>Инструменты</span>",
    en: "Programmes and <span>Tools</span>",
  },
  sert: {
    ru: "Мои <span>Сертификаты</span>",
    en: "My <span>Sertificates</span>",
  },
  exp: {
    ru: "Мой <span>Опыт</span>",
    en: "MY <span>Experience</span>",
  },
  somemore: {
    ru: "Доп <span> информация</span>",
    en: "Some <span> more</span>",
  },
  position1: {
    ru: "Frontend Разработчик",
    en: "Frontend Developer",
  },
  company1: {
    ru: "R52 / Март 2022 - Ноябрь 2022",
    en: "R52 / March 2022 - November 2022",
  },
  r52point1: {
    ru: "Верстка сайтов по макетам FIGMA (добавление блоков / модификация блоков / верстка редизайна / усовершенствование / адаптив) с учетом дальнейшей посадки на CMS, продвижения SEO. В основном сприменением mobile-first подхода",
    en: "Created and maintained front-end code for 20+ eCommerce websites (re-designs, seasonal updates, SEO, animation).",
  },
  r52point2: {
    ru: "Разработка front-end для интернет-магазинов / личных кабинетов и т. д. в&nbsp;составе команды на&nbsp;Javascript и фреймворках",
    en: "Developed the frontend of different websites, online-stores as a part of a team. Developed parts and features based on js, an internal framework like webpack, gulp.",
  },
  r52point3: {
    ru: "Разработка в составе команды с нуля до теста и деплоя проекта и внесения всех правок",
    en: "Assisted the development team in developing 20+ WordPress and Bitrix sites with testing, deployment, and updates.",
  },
  r52point4: {
    ru: "Устранение багов",
    en: "Led troubleshooting, fixed 100+ bugs and other issues, and updated sites throughout production lifecycle.",
  },
  r52point5: {
    ru: "Внедрение нового функционала / блоков на существующие проекты ( в основном, на самописных CMS, Wordpress и Bitrix",
    en: "Collaborated with product team members to implement new feature developments.",
  },
  positionprofosnova: {
    ru: "WEB Разработчик",
    en: "Web Developer",
  },
  companyprofosnova: {
    ru: "ООО ПРОФОСНОВА / Март 2021 - Март 2022",
    en: "ProfOsnova Ltd / March 2021 - March 2022",
  },
  profosnovapoint1: {
    ru: "Адаптивная и кросс-браузерная верстка;",
    en: "Managing work of 5 websites (online-stores, landings, Internet Presence sourses);",
  },
  profosnovapoint2: {
    ru: "Оптимизация кода и скорости загрузки страниц ( в том числе screenreaders и тд);",
    en: "Adding new blocks / modules;",
  },
  profosnovapoint3: {
    ru: "Работа с CMS Wordpress, Bitrix, Joomla;",
    en: "Customization ang changing the existing ones;",
  },
  profosnovapoint4: {
    ru: "Верстка под различные CMS;",
    en: "Controlling errors / SEO optimization;",
  },
  profosnovapoint5: {
    ru: "Добавление модулей на готовые сайты;",
    en: "Working with CMS systems (Wordpress, Bitrix, Joomla);",
  },
  profosnovapoint6: {
    ru: "Приведение существующих интерфейсов web-приложений в соответствие с требованиями W3C Accessibility;",
    en: "Testing (manual);",
  },
  positionads: {
    ru: "WEB Разработчик",
    en: "WEB Developer",
  },
  companyads: {
    ru: "ADS projects group LTD / Декабрь 2020 - Март 2021",
    en: "ADS projects group LTD / December 2020 - March 2021",
  },
  adspoint1: {
    ru: "Разработка сайтов на CMS Wordrpess",
    en: "Developing websites with CMS systems (Wordpress, Joomla).",
  },
  adspoint2: {
    ru: "Разработка новых модулей",
    en: "Adding new moules, Forms customization.",
  },
  adspoint3: {
    ru: "Устранение ошибок, СЕО оптимизация",
    en: "Controlling errors / SEO optimization.",
  },
  adspoint4: {
    ru: "Работа с контентом (дизайн, размещение, отпимизация)",
    en: "Adding / rewriting text, images, video, maps, forms.",
  },
  companyfree: {
    ru: "Фриланс и&nbsp;самозанятость&nbsp;/ Август 2018&nbsp;&mdash; Декабрь 2020",
    en: " Freelance (Self employed) / August 2018&nbsp;&mdash; December 2020",
  },
  freepoint1: {
    ru: "Разработка сайтов &laquo;под ключ&raquo;",
    en: "Website development on a turnkey basis",
  },
  positioninvent: {
    ru: "Руководитель отдела продаж",
    en: "Head of sales Department",
  },
  companyinvent: {
    ru: "ИНВЕНТ, ООО&nbsp;/ Июнь 2015&nbsp;&mdash; Август 2017",
    en: "INVENT Ltd.&nbsp;/ June 2015&nbsp;&mdash; August 2017",
  },
  inventpoint1: {
    ru: "Координация проектов от поиска заказчика до исполнения контракта и подписания Акта выполненных работ",
    en: "The whole project coordination starting with searching for the customer, finishing with signing  an Acceptance Act.",
  },
  inventpoint2: {
    ru: "Контроль поставок",
    en: "Communication with current project partners",
  },
  inventpoint3: {
    ru: "Координация работы всех отделов(бухгалтерии, листистики, переводов, технических, инженерных, производственных) и тд",
    en: "Coordination of all the departments (accounting, logistics, translating, engineering, production)",
  },
  inventpoint4: {
    ru: "Работа с заказчиками",
    en: "Communication with current project partners and Customers",
  },
  inventpoint5: {
    ru: "Подготовка / Перевод документации (презентаций, коммерческих предложений, договоров)",
    en: "Preparation of presentations, commercial offers and contractual documentation",
  },
  companyaerotech: {
    ru: "Аэротэк, ООО&nbsp;/ Август 2013&nbsp;&mdash; Февраль 2015",
    en: "Aerotech Group Ltd.&nbsp;/ August 2013&nbsp;&mdash; February 2015",
  },
  positionaerotech: {
    ru: "Координатор отдела продаж",
    en: "Sales Department coordinator",
  },
  aerotechpoint1: {
    ru: "Планирование, прогноз, контроль, аналитика продаж",
    en: "Sell in and planning, forecasting and control.",
  },
  aerotechpoint2: {
    ru: "Аналитика продаж",
    en: "Monitor and manage sales performance indicators.",
  },
  aerotechpoint3: {
    ru: "Работа с заказчиками и поставщиками",
    en: "Interaction with parners and customers.",
  },
  aerotechpoint4: {
    ru: "Координация работы отдела продаж и смежных отделов",
    en: "Organization of team work.",
  },
  aerotechpoint5: {
    ru: "Работа с документацией",
    en: "Preparation of the project documentation",
  },
  positionfaccin: {
    ru: "Менеджер регионального офиса",
    en: "Manager of the Region office",
  },
  companyfaccin: {
    ru: "Faccin Srl.&nbsp;/ Август 2011&nbsp;&mdash; Июль 2013",
    en: "Faccin Srl.&nbsp;/ August 2011&nbsp;&mdash; July 2013",
  },
  faccinpoint1: {
    ru: "Координация работы компании(продажи и обслуживание) в регионе",
    en: "Region sales and service office coordination.",
  },
  dop1: {
    ru: "Образование:",
    en: "Education",
  },
  dop2: {
    ru: "Увлечения:",
    en: "Hobby:",
  },
  dop1point1: {
    ru: "ННГЛУ, Высшее Лингвистическое (Английский, Итальянский языки), Филолог, Преподаватель русского языка, английского языка, литературы и&nbsp;русского как иностранного.",
    en: "Master's degree at Germanic Languages, Literatures, and Linguistics &mdash; Linguistics University of Nizhny Novgorod (LUNN)",
  },
  dop1point2: {
    ru: "CodeacademyHTML5, CSS3 ,2019-2020",
    en: "CodeacademyHTML5, CSS3 ,2019-2020",
  },
  dop1point4: {
    ru: "Дополнительно закончила несколько курсов по SEO",
    en: "Several courses of SEO optimisation",
  },
  dop1point5: {
    ru: "Несколько курсов по Маркетингу",
    en: "Several courses of Marketing",
  },
  dop2point1: {
    ru: "Фотография",
    en: "Photography",
  },
  dop2point2: {
    ru: "Конный спорт (КМС по конкуру)",
    en: "Сandidate for master of sports of show Jumping",
  },
  dop2point3: {
    ru: "Плавание, дайвинг",
    en: "Swimming, diving",
  },
  projectstitle: {
    ru: "Мои <span>Проекты</span>",
    en: "My <span>Projects</span>",
  },
  projectstab: {
    ru: "Все",
    en: "All",
  },
  h3project1: {
    ru: "Сайт Строительной организации",
    en: "Website of the building company",
  },
  project1desc: {
    ru: "Верстка сайта. Gulp, HTML, SCSS, JAVASCRIPT,FIGMA",
    en: "FIGMA to HTML. Gulp, HTML, SCSS, JAVASCRIPT",
  },
  h3project2: {
    ru: "Вёрстка адаптивного лендинга",
    en: "Adaptive landing page",
  },
  project2desc: {
    ru: "Плавный скролл, микроанимации, якоря, SVG анимация. HTML, CSS, jQuery",
    en: "Microanimation, svg-animation, HTML, CSS, jQuery",
  },
  h3project3: {
    ru: "Сайт с анимациями",
    en: "Adaptive website",
  },
  project3desc: {
    ru: "Верстка сайта. Анимации, Webpack, PUG, SCSS, JAVASCRIPT",
    en: "Microanimation, svg-animation, Webpack, PUG, SCSS, JAVASCRIPT",
  },
  h3project4: {
    ru: "Адаптивный лендинг",
    en: "Adaptive landing page",
  },
  project4desc: {
    ru: "Разработка сайта. Анимации, Gulp, HTML, SCSS, JAVASCRIPT, PHP",
    en: "Microanimation, svg-animation, Gulp, HTML, SCSS, JAVASCRIPT, PHP",
  },
  h3project8: {
    ru: "Адаптивная галерея",
    en: "Adaptive gallery",
  },
  project8desc: {
    ru: "Галерея с изображениями разного формата на чистом JAVASCRIPT",
    en: "Different format image gallery, JAVASCRIPT",
  },
  "": {
    ru: "",
    en: "",
  },
};

changeLanguage();
