const projectTabs = document.querySelector('.projects__tabs');
const projectElement = document.querySelectorAll('.projects__element');
const projectBtn = document.querySelectorAll('.projects__btn');

if (projectTabs) {
  projectTabs.addEventListener('click', (e) => {
    const target = e.target;

    if (target.classList.contains("projects__btn")) {
      const filter = target.dataset.filter;
      projectBtn.forEach(el => {
        el.classList.remove('projects__btn--active');
        target.classList.add('projects__btn--active');
      });

      projectElement.forEach(el => {
        el.style.display = 'none';
        el.classList.add('projects__element--hidden');
        el.classList.remove('projects__element--visible');
      });
      document.querySelectorAll(`[data-target="${filter}"]`).forEach(el => {
        el.closest('.projects__element').classList.remove('projects__element--hidden');
        el.closest('.projects__element').classList.add('projects__element--visible');
      });

      if (filter == 'all') {
        projectElement.forEach(el => {
          el.classList.remove('projects__element--hidden');
          el.classList.add('projects__element--visible');
        });
      }
    }
  });
}
