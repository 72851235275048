const popups = document.querySelectorAll('.popup');
const menuBtns = document.querySelector('.nav__list');

let unlock = true;

const timeout = 500;

if (menuBtns) {
  menuBtns.addEventListener('click', (e) => {

    const popupName = e.target.getAttribute("href").replace('#', '');
    const currentPopup = document.getElementById(popupName);
    popupOpen(currentPopup);
    e.preventDefault();
  });

  const popupCloseItem = document.querySelectorAll('.popup__close-area');

  if (popupCloseItem.length > 0) {
    for (let i = 0; i < popupCloseItem.length; i++) {
      const el = popupCloseItem[i];
      el.addEventListener('click', function (e) {
        popupClose(el.closest('.popup'));
        e.preventDefault();
      })
    }
  }

  function popupOpen(currentPopup) {
    if (currentPopup && unlock) {
      const popupActive = document.querySelector('.popup.open');
      if (popupActive) {
        popupClose(popupActive, false);
      }
      currentPopup.classList.add('open');
    }
  }

  function popupClose(popupActive, doUnlock = true) {
    if (unlock) {
      popupActive.classList.remove('open');
    }
  }
}

